<template >
  <div class="containter">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div class="listCell" v-for="item in list" :key="item.id">
          <div class="listItem">
            <div v-if="item.status===2" class="notApprovedIcon">未审核</div>
            <img v-if="item.status===3" :src="approvedImg" class="listItemSignInImg" />
            <div class="listItemTitleLine">
              <div class="avtorArea">
                <!-- <img :src="item.executorAvatar" style="width:100%;height:100%;" /> -->
                <van-image fit="cover" :src="item.executorAvatar" style="width:100%;height:100%;">
                  <template v-slot:error>
                    <img :src="errorPicAvatar" style="width:100%;height:100%;" />
                  </template>
                </van-image>
              </div>
              <div class="listItemTitleLineContent">
                <div class="listItemTitleLineContent1">{{ item.branchName}}</div>
                <div class="listItemTitleLineContent2">{{ item.completeTime}}</div>
              </div>
            </div>
            <div class="baseLine"></div>
            <div class="mainTitleLine">
              <span class="marginLeft10">{{item.sname}}</span>
            </div>
            <div class="descriptionLine">
              <span class="descriptionMargin">{{item.description}}</span>
            </div>
            <div class="mainImgArea" @click="gotoDetail(item)">
              <!-- <img :src="item.titleImg" class="mainImg" /> -->
              <van-image fit="cover" :src="item.titleImg" class="mainImg">
                <template v-slot:error>
                  <img :src="errorImg" style="width:100%;height:100%;" />
                </template>
              </van-image>
            </div>
            <div class="mainBottomLine">
              <div class="leftMainArea"></div>
              <div class="rightMainArea">
                <!-- 立刻领取 -->
                <div v-if="item.status===2" class="baomingButton backRed">去审核</div>
                <div
                  v-if="item.status===3"
                  class="baomingButton backRed"
                  @click="gotoDetail(item)"
                >查看详情</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { approveList } from '../../api/task';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'TaskApproveList',

  data() {
    return {
      // 任务的四种状态 1-待领取 2-进行中 3-已完成 4-已终止
      // 审核的两种状态 1-待审核 2-已审核
      searchIconVisible: true,
      redLove: require('../activity/imgs/redHeart.png'),
      greyLove: require('../activity/imgs/greyHeart.png'),
      eyeIcon: require('../activity/imgs/eyeIcon.png'),
      approvedImg: require('./imgs/approved.png'),
      errorImg: require('../../assets/img/list.png'),
      errorPicAvatar: require('../../assets/img/list-compre.png'),
      searchInput: '',
      placehold: '活动名称',
      selectRow: 1,
      param: {
        page: 0,
        size: 5,
        total: 20
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      fakeList: [
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: true,
          enrollStatus: false,
          zan: 23,
          ownZan: true,
          eyeNum: 60,
          img: '',
          taskStatus: 1,
          approveStatus: 1,
          joinName: '李翠花'
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: false,
          enrollStatus: true,
          zan: 23,
          ownZan: false,
          eyeNum: 60,
          img: '',
          taskStatus: 2,
          approveStatus: 1,
          joinName: '李翠花'
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: false,
          enrollStatus: true,
          zan: 23,
          ownZan: true,
          eyeNum: 60,
          img: '',
          taskStatus: 3,
          approveStatus: 1,
          joinName: '李翠花'
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: true,
          enrollStatus: false,
          zan: 23,
          ownZan: true,
          eyeNum: 60,
          img: '',
          taskStatus: 3,
          approveStatus: 2,
          approveName: '蓝城',
          counts: 5,
          joinName: '李翠花'
        },
        {
          depart: '宝华洲社区小西门党支部',
          time: '03-31 14:00:43',
          name: '315结对帮扶活动',
          signInStatus: true,
          enrollStatus: false,
          zan: 23,
          ownZan: false,
          eyeNum: 60,
          img: '',
          taskStatus: 4,
          approveStatus: 2,
          approveName: '蓝城',
          counts: 5,
          joinName: '李翠花'
        }
      ],
      list: []
    };
  },
  watch: {},
  created() {
    dd.biz.navigation.setTitle({
      title: '任务审批', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const param = this.param;
      param.page += 1;
      const searchParam = { ...param };
      searchParam.status = this.selectRow;
      // 调接口
      approveList({
        current: searchParam.page,
        size: 5
      })
        .then(res => {
          if (res.success) {
            const activityList = res.data.records;
            let list = this_.list;
            list = list.concat(activityList);
            this_.list = JSON.parse(JSON.stringify(list));
            console.log(this_.list);
            param.total = res.data.total;
            this_.param = param;
            // 加载状态结束
            this_.loading = false;
            this_.refreshing = false;
            this_.error = false;
            // 数据全部加载完成
            if (this_.list.length >= param.total) {
              this_.finished = true;
            }
          } else {
            // 加载状态结束
            this_.error = true;
            console.log(res.msg);
          }
        })
        .catch(err => {
          // 加载状态结束
          this_.error = true;
          console.log(err);
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];
      this.param.page = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getRecords();
    },
    //加载更多数据
    onLoad() {
      const timer = setTimeout(() => {
        this.getRecords();
        this.loading = false;
        clearTimeout(timer);
      }, 500);
    },
    // 查询的改变
    changeList(num) {
      this.selectRow = num;
      this.param.page = 0;
      this.finished = false;
      this.list = [];
      this.getRecords();
    },
    gotoDetail(row) {
      console.log(row);
      this.$router.push({
        name: 'taskApproveDetail',
        query: { detailId: row.id }
      });
    },
    getInputChange() {
      this.searchIconVisible = false;
    },
    blurChange() {
      this.searchIconVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-icon {
  display: flex;
  align-items: center;
}
/deep/ .van-cell {
  padding: 0px;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headSearchLine {
  width: 100%;
  height: 40 * $px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.searchInput {
  width: 355 * $px;
  height: 29 * $px;
  line-height: 29 * $px;
  border-radius: 14.5 * $px;
  background-color: #f5f5f5;
  padding: 0px;
}
.searchIcon {
  width: 12 * $px;
  height: 12 * $px;
  position: absolute;
  top: 13.5 * $px;
  left: 146 * $px;
  z-index: 1;
}
.radioButtonLine {
  height: 34 * $px;
  width: 100%;
  background-color: #ffffff;
}
.radioButtonItem {
  width: 33.33%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.radioButtonFont {
  font-size: 14 * $px;
  color: #555555;
  margin-bottom: 7 * $px;
}
.radioButtonBottomLine {
  width: 30 * $px;
  height: 2 * $px;
  border-radius: 0.5 * $px;
  background-color: #ffffff;
}
.listItem {
  width: 100%;
  height: 100%;
  position: relative;
}
.listCell {
  margin: 10 * $px 10 * $px 0 10 * $px;
  width: 355 * $px;
  height: 323.5 * $px;
  background-color: #ffffff;
}
.listItemTitleLine {
  width: 100%;
  height: 80 * $px;
}
.avtorArea {
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 20 * $px;
  overflow: hidden;
  background-color: grey;
  margin: 10 * $px;
  float: left;
}
.listItemTitleLineContent {
  width: 280 * $px;
  height: 100%;
  float: left;
}
.listItemTitleLineContent1 {
  font-size: 14 * $px;
  color: #555555;
  margin: 12 * $px 45 * $px 0 * $px 10 * $px;
}
.listItemTitleLineContent2 {
  font-size: 11 * $px;
  color: #999999;
  margin-left: 10 * $px;
}
.baseLine {
  width: 335 * $px;
  height: 0.5 * $px;
  margin-left: 10 * $px;
  background-color: #eeeeee;
}
.mainTitleLine {
  width: 100%;
  height: 39 * $px;
  line-height: 39 * $px;
  color: #222222;
  font-size: 16 * $px;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.mainImgArea {
  width: 100%;
  height: 130 * $px;
  display: flex;
  justify-content: center;
}
.mainImg {
  width: 335 * $px;
  height: 100%;
}
.mainBottomLine {
  width: 100%;
  height: 44 * $px;
}
.leftMainArea {
  width: 30%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rightMainArea {
  width: 70%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftMainAreaImg {
  width: 16 * $px;
  height: 14.5 * $px;
  margin-left: 12 * $px;
}
.leftMainAreaFont {
  width: 50 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainAreaFont2 {
  width: 62 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainEyeImg {
  width: 16 * $px;
  height: 12 * $px;
}
.leftMainAreaFontNum {
  font-size: 12 * $px;
  color: #555555;
}
.leftMainAreaFontNumNow {
  color: #2797ff;
  font-size: 16 * $px;
}
.baomingButton {
  width: 70 * $px;
  height: 24 * $px;
  border-radius: 12 * $px;
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12 * $px;
  margin-right: 10 * $px;
  margin-left: 10 * $px;
}
.baomingButtonFinish {
  width: 90 * $px;
  height: 24 * $px;
  border-radius: 12 * $px;
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12 * $px;
  margin-right: 10 * $px;
  margin-left: 10 * $px;
}
.backRed {
  background-color: #ff192f;
  color: #ffffff;
}
.backWhite {
  background-color: #ffffff;
  color: #ff192f;
}
.backGrey {
  background-color: #ffffff;
  color: #999999;
  border: #999999 1 * $px solid;
}
.listItemSignInImg {
  width: 70 * $px;
  height: 70 * $px;
  position: absolute;
  top: 15 * $px;
  right: 15 * $px;
}
.height10 {
  width: 100%;
  height: 10 * $px;
  background-color: #ffffff;
}
.peopleFont {
  color: #555555;
  font-size: 12 * $px;
  margin-left: 13 * $px;
}
.notApprovedIcon {
  position: absolute;
  top: 12 * $px;
  right: 12 * $px;
  width: 50 * $px;
  height: 22 * $px;
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  border-radius: 11 * $px;
  color: #ff192f;
  font-size: 12 * $px;
  line-height: 22 * $px;
  text-align: center;
}
.descriptionLine {
  height: 30 * $px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 30 * $px;
}
.descriptionMargin {
  margin-left: 11 * $px;
  margin-right: 11 * $px;
  font-size: 14 * $px;
  color: #555555;
}
</style>



